export const AUTH_TOKEN = "auth_token";
export const REFRESh_TOKEN = "refresh_token";
export const limitDropdown = [
  { _id: 1, label: "All Items", value: "" },
  { _id: 2, label: "5 Items", value: 5 },
  { _id: 2, label: "10 Items", value: 10 },
  { _id: 3, label: "20 Items", value: 20 },
  { _id: 4, label: "30 Items", value: 30 },
  { _id: 5, label: "50 Items", value: 50 },
  { _id: 6, label: "100 Items", value: 100 },
];

export const statusDropdown = [
  {
    _id: 1,
    label: "Active",
    value: 1,
  },
  {
    _id: 2,
    label: "Inactive",
    value: 2,
  },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formData = "multipart/form-data";
export const tooltipData = {
  labelName: "data-tooltip-content",
  idName: "data-tooltip-id",
  id: "my-tooltip",
};
