import React, { useState } from "react";
import ButtonLoder from "./buttonLoder/ButtonLoder";

const Modal = ({
  isOpen = "false",
  children,
  onCancel = () => {},
  cancelText,
  onSucess = () => {},
  sucessText,
  successClasses,
  canelClasses,
  modalClass = "",
  isDisabled = false,
  isCancelBtnEnable = true,
  isSucessBtnEnable = true,
  type,
}) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-sm">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className={`bg-white rounded-lg p-8 z-10 relative ${modalClass} `}>
        <button
          className="absolute top-0  w-10 h-10 rounded-full px-4 right-0  text-gray-500 hover:text-gray-800"
          onClick={onCancel}
        >
          <i className="fa-solid fa-xmark text-lg"></i>
        </button>
        <div className="modal-content">{children}</div>
        <div className="modal-buttons mt-4 flex justify-end">
          {onCancel && isCancelBtnEnable && (
            <button
              className={`mr-2 bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded-md ${canelClasses} `}
              onClick={onCancel}
            >
              {cancelText || "Cancel"}
            </button>
          )}

          {onSucess && isSucessBtnEnable && (
            <button
              className={`bg-indigo-900 flex ${
                isDisabled ? "opacity-50 pointer-events-none" : ""
              } hover:bg-indigo-800 px-4 py-2 rounded-md text-white ${successClasses}`}
              onClick={onSucess}
              type={type ? type : "button"}
            >
              {isDisabled && <ButtonLoder />}
              {sucessText || "Sumbit"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
