import React from "react";
import { Link } from "react-router-dom";
import ButtonLoder from "../../common/buttonLoder/ButtonLoder";

const Button = ({
  buttonClasses,
  buttonType,
  buttonIcon,
  buttonIconPosition,
  buttonLabel,
  buttonLabelClasses,
  buttonFunction,
  buttonHasLink,
  buttonLink,
  functions,
  buttonDisabled,
  isLoaderDisabled = false,
  toolTipLabel = "",
  ...props
}) => {
  return (
    <>
      {buttonHasLink ? (
        <Link
          to={buttonLink}
          className={
            "bg-indigo-900 flex items-center justify-center gap-1 text-white text-center rounded text-lg h-10 py-0 px-3 transition-all duration-200 " +
            buttonClasses
          }
          data-tooltip-id="my-tooltip"
          data-tooltip-content={toolTipLabel}
        >
          {buttonIconPosition === "left" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
          {buttonLabel && (
            <span className={"text-sm font-medium " + buttonLabelClasses}>
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
        </Link>
      ) : (
        <button
          type={buttonType}
          className={
            " bg-indigo-900 flex items-center justify-center gap-1 text-white text-center rounded text-lg h-10 py-0 px-3 transition-all duration-200 " +
            buttonClasses
          }
          data-tooltip-id="my-tooltip"
          data-tooltip-content={toolTipLabel}
          onClick={buttonFunction}
          disabled={buttonDisabled}
        >
          {buttonDisabled && !isLoaderDisabled && (
            <div className="w-4">
              <ButtonLoder />
            </div>
          )}

          {buttonIconPosition === "left" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}

          {buttonLabel && (
            <span className={"text-sm font-medium  "}>{buttonLabel}</span>
          )}
          {buttonIconPosition === "right" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
        </button>
      )}
    </>
  );
};

export default Button;
