import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const SidebarMenuItem = ({
  menuLink,
  menuIcon,
  menuIconColor,
  menuLabel,
  isMenuOpen,
  ...props
}) => {
  const location = useLocation();

  return (
    <>
      <NavLink
        to={menuLink}
        className={({ isActive }) =>
          "group flex h-10 items-center rounded overflow-hidden transition-all duration-200 " +
          (location.pathname.includes(menuLink)
            ? " !bg-white [&>*]:!text-indigo-900"
            : "")
        }
      >
        {({ isActive }) => (
          <>
            <div
              className={
                "w-full h-full flex gap-2 items-center text-lg !text-white transition-all duration-200 group-hover:!bg-white group-hover:!text-indigo-900  " +
                menuIconColor +
                (location.pathname.includes(menuLink)
                  ? " !text-indigo-900"
                  : "")
              }
            >
              <span className="flex items-center justify-center h-full w-8 ">
                <i className={"fa-light fa-fw text-md" + menuIcon}></i>
              </span>
              {!isMenuOpen && <div className="text-md">{menuLabel}</div>}
            </div>
          </>
        )}
      </NavLink>
    </>
  );
};

export default SidebarMenuItem;
