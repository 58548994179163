export const roleCode = {
  company_administrator: "COMPANY-ADMINISTRATOR",
  company_employee: "COMPANY-EMPLOYEE",
  company_manager: "COMPANY-MANAGER",
  admin: "ADMIN",
};
export const goal_type = {
  quantifiable: "quantifiable",
  unquantifiable: "unquantifiable",
};
