import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/opacity.css";

const Image = ({ src, width, height, alt, effect, classes, onChange }) => {
  const [isImageError, setIsImageError] = useState(false);
  return (
    <>
      {!isImageError ? (
        <LazyLoadImage
          src={src}
          width={width}
          height={height}
          alt={alt}
          effect={effect}
          onChange={onChange}
          className={"w-full h-full " + classes}
          onError={() => setIsImageError(true)}
        />
      ) : (
        <LazyLoadImage
          src={"/images/avtar.svg"}
          width={width}
          height={height}
          alt={alt}
          effect={effect}
          onChange={onChange}
          className={"w-full h-full " + classes}
          onError={() => setIsImageError(true)}
        />
      )}
    </>
  );
};

export default Image;
