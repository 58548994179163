import React from "react";
import { classNames } from "../../helpers/common/classNames";

const Checkbox = ({
  checkboxName,
  checkboxInputClass,
  isChecked,
  checkboxLabel,
  checkboxLableClass,
  onChange,
  value,
  ...props
}) => {
  return (
    <>
      <div className="relative flex items-center mr-3">
        <input
          type="checkbox"
          name={checkboxName}
          className={
            "peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer " +
            checkboxInputClass
          }
          checked={isChecked}
          onChange={(e) => onChange(e.target.checked, value)}
        />
        <div
          className={classNames(
            "w-5 h-5 min-w-[20px] mr-3 border border-slate-300 rounded flex items-center justify-center text-sm bg-white text-black/0 peer-checked:bg-indigo-500 peer-checked:border-indigo-500 peer-checked:text-white",
            checkboxInputClass
          )}
        >
          <i className="fa-light fa-fw fa-check"></i>
        </div>
        <div className={"text-sm text-slate-500 w-full " + checkboxLableClass}>
          {checkboxLabel}
        </div>
      </div>
    </>
  );
};

export default Checkbox;
