import { fetchData } from "../helpers/fetchData";
import { roleCode } from "./enum";
import { toast } from "react-toastify";

export const rearangedDeletedData = (selectedItems, list) => {
  let arr = list.filter((item, index) => !selectedItems.includes(item._id));
  return arr;
};
export const isAdministrator = (arr) => {
  let obj = arr.find(
    (item, index) => item.code === roleCode.company_administrator
  );
  if (obj && Object.keys(obj).length) {
    return true;
  } else {
    return false;
  }
};
export const isManager = (arr) => {
  let obj = arr.find((item, index) => item.code === roleCode.company_manager);
  if (obj && Object.keys(obj).length) {
    return true;
  } else {
    return false;
  }
};
export const handleDownload = (filePath, folderName) => {
  // Create an anchor element
  const anchor = document.createElement("a");
  // Set the href attribute to the URL of the file to download
  anchor.href = filePath; // Change "/path/to/your/sample_file.ext" to the actual path of your file
  // Set the download attribute to specify the filename
  anchor.download = folderName; // Change "sample_file.ext" to your desired filename
  // Simulate a click on the anchor element to trigger the download
  anchor.click();
};

export const reorder = (data = [], startIndex, endIndex) => {
  const result = Array.from(data);
  const [startIndexObj] = result.splice(startIndex, 1);
  // const [endIndexObj] = result.splice(endIndex, 1);
  result.splice(endIndex, 0, startIndexObj);
  // result.splice(startIndex, 0, endIndexObj);

  return result;
};
// ====>>For journal<<=====
export function withTryCatch(func) {
  return async (...args) => {
    try {
      await func(...args);
    } catch (error) {
      toast.error(error.message || "Somthing went wrong try again please");
    }
  };
}

export const getOrdinalSuffix = (n) => {
  if (n) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }
};
export const removeTag = (html) => {
  return html?.replace(/<[^>]*>/g, "");
};
export const exportToCSV = (data, filename) => {
  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      // If the value contains double quotes, escape them by doubling them
      if (value.includes('"')) {
        return `"${value.replace(/"/g, '""')}"`;
      }
      // If the value contains a comma, enclose it in double quotes
      if (value.includes(",")) {
        return `"${value}"`;
      }
    }
    return value;
  };

  const csvHeader =
    Object.keys(data[0])
      .map((key) => escapeCsvValue(key))
      .join(",") + "\n";

  const csvRows = data
    .map((row) =>
      Object.values(row)
        .map((value) => escapeCsvValue(value))
        .join(",")
    )
    .join("\n");

  const csvContent = csvHeader + csvRows;

  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "data.csv";
  a.click();

  URL.revokeObjectURL(url);
};

export const handleStatusChange = async (arr, item, index, endpoints) => {
  let resp = await fetchData("put", endpoints, {
    body: { id: [item?._id], status: `${item.status === "active" ? 2 : 1}` },
  });
  if (resp?.data?.status === 200) {
    toast.success(resp?.data?.message);
    arr[index].status = arr[index]?.status === "active" ? "inactive" : "active";
  }
  return arr;
};
export const removeHtmlTags = (htmlString) => {
  return htmlString.replace(/<[^>]*>/g, "");
};
export const addPrefixOnNumber = (number) => {
  if (number < 10) {
    return "0" + number;
  } else {
    return number;
  }
};
export const calculateAge = (birthDate) => {
  var today = new Date();
  var birthDate = new Date(birthDate);
  var age = today.getFullYear() - birthDate.getFullYear();
  var monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};
