import React from "react";
import Search from "../form/Search";

function HeadingWrapper({ title, className = "m-2 mb-6", children }) {
  return (
    <>
      {/* <div className="flex justify-between "> */}
      <h1 className={`font-bold text-2xl inline-block ${className}`}>
        {title}
      </h1>
      {/* </div> */}
      {/* {children} */}
    </>
  );
}

export default HeadingWrapper;
