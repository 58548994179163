import React, { useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import Checkbox from "../components/form/Checkbox";
import Input from "../components/form/Input";
import { useNavigate } from "react-router-dom";
import { withTryCatch } from "../utils/function";
import { fetchData } from "../helpers/fetchData";
import { toast } from "react-toastify";
function Login() {
  setTitle("Journal | Dashboard");
  const navigate = useNavigate();
  const location = useLocation();
  const data = {
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  };
  const [paylodData, setpayLodData] = useState({ email: "", password: "" });
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  const [isBtnDissable, setIsBtnDissable] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [forgetPasswordData, setForgetPasswordData] = useState(data);
  const { siteSetting } = useSelector((state) => state.setting);

  const handleLogin = withTryCatch(async (e) => {
    setIsBtnDissable(true);

    if (!paylodData.email || !paylodData.password) {
      toast.warn("Please enter your credentials");
      setIsBtnDissable(false);
      return;
    }
    let resp = await fetchData("post", "admin-login", {
      body: { ...paylodData },
    });

    if (resp?.data?.status === 200) {
      toast.success(resp.data.message);
      localStorage.setItem("auth_token", resp?.data?.accessToken);
      window.dispatchEvent(new Event("auth_token"));
    } else {
      // toast.warn(resp.data.message || "Somthing went wrong please try again");
    }
    setIsBtnDissable(false);
  });
  const handleForgetPassword = async (e) => {};
  const handleSendOtp = async (e) => {};

  return (
    <>
      {!isSendOtp ? (
        <div className="w-full h-screen flex justify-center items-center bg-slate-100">
          <div className="w-full max-w-sm bg-white rounded shadow-md py-8 px-6">
            <div className=" divide-slate-200 space-y-4">
              <div className="relative">
                <div className="mx-auto ">
                  <img
                    className="h-full w-full object-contain "
                    src={"/images/logo_login.png"}
                    alt={"Logo"}
                  />
                </div>
                <h1 className="text-xl font-semibold text-center text-slate-700">
                  Login
                </h1>
                <div className="text-sm text-slate-400 text-center font-light">
                  Please use your credentials to login
                </div>
              </div>
              <div className="relative">
                <Input
                  label={"Email"}
                  labelClasses={"!text-xs"}
                  inputType={"email"}
                  inputPlaceholder={"Email address"}
                  errorType={""}
                  onChange={(e) =>
                    setpayLodData((prev) => ({
                      ...prev,
                      email: e?.target?.value,
                    }))
                  }
                  value={paylodData?.email || ""}
                  onEnterClicked={handleLogin}
                />
              </div>

              <div className="relative">
                <Input
                  label={"Password"}
                  labelClasses={"!text-xs"}
                  inputType={"password"}
                  inputPlaceholder={"Your Password"}
                  errorType={""}
                  value={paylodData.password || ""}
                  onChange={(e) =>
                    setpayLodData((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  onEnterClicked={handleLogin}
                />
                {showWarning && !paylodData.email && (
                  <span className="text-red-500 text-xs">
                    Email is required
                  </span>
                )}
              </div>

              <div className="relative flex justify-between items-center">
                <div className="">
                  <Checkbox
                    checkboxName={"remember_me"}
                    isChecked={rememberMe}
                    checkboxLabel={"Remember Me"}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                </div>
                <div
                  className="text-sm text-slate-500 cursor-pointer hover:text-blue-500"
                  onClick={() => setIsSendOtp(true)}
                >
                  Forget Password
                </div>
              </div>
              <div className="relative flex justify-center">
                <Button
                  buttonClasses={""}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-arrow-right-to-arc"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Login"}
                  buttonHasLink={false}
                  buttonDisabled={isBtnDissable}
                  buttonFunction={(e) => handleLogin(e)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : isForgetPassword && isSendOtp ? (
        <div className="w-full h-screen flex justify-center items-center bg-slate-100">
          <div className="w-full max-w-sm bg-white rounded shadow-md py-8 px-6">
            <div className="divide-y divide-slate-200 space-y-4">
              <div className="relative">
                <div className="mx-auto my-2 w-20 h-20">
                  <Image
                    src={
                      siteSetting
                        ? siteSetting.logo?.url
                        : "../../images/noImage.jpg"
                    }
                  />
                </div>
                <h1 className="text-xl font-semibold text-center text-slate-700">
                  Forget Password
                </h1>
                <div className="text-sm text-slate-400 text-center font-light">
                  Please use your credentials
                </div>
              </div>
              <div className="relative">
                <Input
                  label={"Email"}
                  labelClasses={"!text-xs"}
                  inputType={"email"}
                  inputPlaceholder={"Email address"}
                  errorType={""}
                  onChange={(e) =>
                    setForgetPasswordData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  value={forgetPasswordData.email || ""}
                  onEnterClicked={handleForgetPassword}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Otp"}
                  labelClasses={"!text-xs"}
                  inputType={"number"}
                  inputPlaceholder={"Enter Otp"}
                  errorType={""}
                  onChange={(e) =>
                    setForgetPasswordData((prev) => ({
                      ...prev,
                      otp: e.target.value,
                    }))
                  }
                  value={forgetPasswordData.otp || ""}
                  onEnterClicked={handleForgetPassword}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Password"}
                  labelClasses={"!text-xs"}
                  inputType={"password"}
                  inputPlaceholder={"Your Password"}
                  errorType={""}
                  value={forgetPasswordData.password || ""}
                  onChange={(e) =>
                    setForgetPasswordData((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  onEnterClicked={handleForgetPassword}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Confirm Password"}
                  labelClasses={"!text-xs"}
                  inputType={"password"}
                  inputPlaceholder={"Re-enter the password"}
                  errorType={""}
                  value={forgetPasswordData.confirmPassword || ""}
                  onChange={(e) =>
                    setForgetPasswordData((prev) => ({
                      ...prev,
                      confirmPassword: e.target.value,
                    }))
                  }
                  onEnterClicked={handleForgetPassword}
                />
              </div>

              <div className="relative flex justify-center">
                <Button
                  buttonClasses={""}
                  buttonType={"button"}
                  buttonLabel={"Save"}
                  buttonHasLink={false}
                  buttonDisabled={isBtnDissable}
                  buttonFunction={(e) => handleForgetPassword(e)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex justify-center items-center bg-slate-100">
          <div className="w-full max-w-sm bg-white rounded shadow-md py-8 px-6">
            <div className="divide-y divide-slate-200 space-y-4">
              <div className="relative">
                <div className="mx-auto my-2 w-20 h-20">
                  <Image
                    src={
                      siteSetting
                        ? siteSetting.logo?.url
                        : "../../images/noImage.jpg"
                    }
                  />
                </div>
                <h1 className="text-xl font-semibold text-center text-slate-700">
                  Send Otp
                </h1>
                <div className="text-sm text-slate-400 text-center font-light">
                  Please use your credentials
                </div>
              </div>

              <div className="relative">
                <Input
                  label={"Email"}
                  labelClasses={"!text-xs"}
                  inputType={"email"}
                  inputPlaceholder={"Email address"}
                  errorType={""}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email || ""}
                  onEnterClicked={handleSendOtp}
                />
              </div>

              <div className="relative flex justify-between items-center">
                <div
                  className="text-sm text-slate-500 cursor-pointer hover:text-blue-500"
                  onClick={() => setIsSendOtp(false)}
                >
                  <i className="fa-duotone fa-backward mx-2"></i>
                  Back
                </div>
              </div>
              <div className="relative flex justify-center">
                <Button
                  buttonClasses={""}
                  buttonType={"submit"}
                  buttonLabel={"Send Otp"}
                  buttonHasLink={false}
                  buttonDisabled={isBtnDissable}
                  buttonFunction={handleSendOtp}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
