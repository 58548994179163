import React, { useRef } from "react";
import Image from "../Image";
import Select from "../../form/Select";
import Button from "../../form/Button";
import Textarea from "../../form/Textarea";
import Input from "../../form/Input";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import TableAction from "./TableAction";
import Checkbox from "../../form/Checkbox";
import { classNames } from "../../../helpers/common/classNames";

const Td = ({
  type,
  data,
  dropdownData,
  image,
  statusLabel,
  statusType,
  onEmailChange,
  onInputChange,
  statusClasses,
  onStatusChange,
  inputType,
  inputClasses,
  inputPlaceholder,
  checkboxChecked,
  onCheckboxChange,
  align,
  index,
  isStatusChangeRequired,
  onClick,
}) => {
  const flatPicker = useRef(null);
  const status = {
    success: "bg-green-100 text-green-600",
    danger: "bg-rose-100 text-rose-600",
    warning: "bg-amber-100 text-amber-600",
    info: "bg-sky-100 text-sky-600",
    "": "bg-slate-100 text-slate-600",
  };

  return (
    <td
      className={classNames(
        `  py-3 px-2 max-w-[240px]`,
        align === "left"
          ? "text-left"
          : align === "right"
          ? "text-right"
          : align === "center"
          ? "text-center"
          : ""
      )}
    >
      {type === "text" && (
        <div className={`text-sm font-normal text-slate-600 w-full `}>
          {data}
        </div>
      )}
      {type === "editorData" && (
        <div
          className="text-sm font-normal text-slate-600"
          dangerouslySetInnerHTML={{ __html: data }}
        ></div>
      )}
      {type === "checkbox" && (
        <Checkbox isChecked={checkboxChecked} onChange={onCheckboxChange} />
      )}
      {type === "user" && (
        <div className="relative flex items-center">
          <div className="w-8 h-8 min-w-[32px] overflow-hidden rounded-full ">
            <Image
              src={image}
              alt={"data"}
              width={"100%"}
              height={"100%"}
              effect={"blur"}
              classes={"object-cover"}
            />
          </div>
          <div className="text-sm font-normal text-slate-600 mx-2">{data}</div>
        </div>
      )}
      {type === "image" && (
        <div className="w-44 h-28 min-w-[40px] overflow-hidden rounded border-2">
          <Image
            src={image}
            alt={"data"}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover"}
          />
        </div>
      )}
      {type === "video" && (
        <video width="180" height="300" controls>
          <source src={image} type="video/mp4" />
        </video>
      )}
      {type === "pdf" && (
        <embed src={image} type="application/pdf" width="180" height="180" />
      )}
      {type === "status" && (
        <div className="flex items-center">
          <div
            className={
              `text-[10px] font-medium leading-tight rounded cursor-pointer py-1 px-2 ${
                isStatusChangeRequired ? "cursor-pointer" : ""
              }` + status[statusType]
            }
            onClick={() => {
              if (isStatusChangeRequired) {
                onClick();
              }
            }}
          >
            {statusLabel}
          </div>
        </div>
      )}
      {type === "emailStatus" && (
        <div className="flex items-center">
          <div
            className={
              "text-[10px] font-medium leading-tight rounded py-1 px-2 cursor-pointer " +
              status[statusType]
            }
            onClick={onEmailChange}
          >
            {statusLabel}
          </div>
        </div>
      )}
      {type === "itemStatus" && (
        <div className="flex items-center">
          <div
            className={
              `text-[10px] font-medium leading-tight rounded py-1 px-2 ${
                typeof onStatusChange === "function" ? "cursor-pointer" : null
              } ` + status[statusType]
            }
            onClick={onStatusChange}
          >
            {statusLabel}
          </div>
        </div>
      )}
      {type === "action" && (
        <div className="flex items-center justify-end">
          <TableAction dropdownData={dropdownData} xPlacement={"right"} />
        </div>
      )}
      {type === "select" && (
        <Select
          xPlacement={"left"}
          dropdownClass={"!w-full"}
          dropdownData={[
            { _id: -1, label: "Select", value: 0 },
            ...[
              { _id: 1, label: "Yes", value: 1 },
              { _id: 2, label: "No", value: 2 },
            ],
          ]}
        />
      )}
      {type === "date" && (
        <Flatpickr
          ref={flatPicker}
          className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
          placeholder={""}
        />
      )}
      {type === "input" && (
        <Input
          value={data}
          onChange={onInputChange}
          inputClasses={"!w-1/2"}
          inputType={"number"}
        />
      )}
      {type === "textarea" && <Textarea />}
    </td>
  );
};

export default Td;
