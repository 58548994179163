import React from "react";

const Search = ({
  placeholder,
  inputClasses,
  iconClasses,
  onChange,
  mainClass,
}) => {
  return (
    <>
      <div
        className={`relative flex h-10 w-full bg-white rounded border border-slate-200 ${mainClass}`}
      >
        <div
          className={
            "w-10 h-10 min-w-[40px] flex items-center justify-center text-slate-400 " +
            iconClasses
          }
        >
          <i className="fa-regular fa-fw fa-magnifying-glass"></i>
        </div>
        <input
          type="text"
          placeholder={placeholder}
          className={
            "!border-0 !ring-0 bg-transparent text-slate-600 text-sm w-full p-0 pr-3 " +
            inputClasses
          }
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default Search;
