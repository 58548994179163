import React, { useEffect, useState } from "react";
import SidebarMenuBlocks from "../components/navigation/SidebarMenuBlocks";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const DefaultSidebar = ({ isMenuOpen }) => {
  const { siteSetting } = useSelector((state) => state.setting);
  const { user } = useSelector((state) => state.user);
  const [sideBar, setSidebar] = useState([]);

  const adminSidebarMenu = [
    {
      _id: crypto.randomUUID(),
      menuTitle: "Home",
      menuData: [
        {
          _id: 1,
          menuLabel: "Dashboard",
          menuLink: "/dashboard",
          menuIcon: "fa-duotone fa-gauge ",
          menuIconColor: "text-indigo-900",
        },
      ],
    },
    {
      _id: crypto.randomUUID(),
      menuTitle: "Tasks",
      menuData: [
        {
          _id: 2,
          menuLabel: "Daily",
          menuLink: "/daily",
          menuIcon: "fa-regular fa-timer",
          menuIconColor: "text-indigo-900",
        },
        {
          _id: 3,
          menuLabel: "Months",
          menuLink: "/months",
          menuIcon: "fa-solid fa-calendar-days",
          menuIconColor: "text-indigo-900",
        },
        {
          _id: 4,
          menuLabel: "Diary",
          menuLink: "/diary",
          menuIcon: "fa-solid fa-book",
          menuIconColor: "text-indigo-900",
        },
      ],
    },
    {
      _id: crypto.randomUUID(),
      menuTitle: "Users",
      menuData: [
        {
          _id: 5,
          menuLabel: "Users",
          menuLink: "/users",
          menuIcon: "fa-regular fa-user",
          menuIconColor: "text-indigo-900",
        },
      ],
    },
    {
      _id: crypto.randomUUID(),
      menuTitle: "Plans",
      menuData: [
        {
          _id: 5,
          menuLabel: "Plans",
          menuLink: "/plans",
          menuIcon: "fa-solid fa-atom-simple",
          menuIconColor: "text-indigo-900",
        },
      ],
    },
  ];

  return (
    <>
      <div className="relative w-full bg-indigo-900 divide-y divide-slate-800">
        <div
          className={
            `relative flex items-center gap-4 h-16 transition-all duration-100
            
             ` + (isMenuOpen ? "px-3" : "px-4")
          }
        >
          <Link className="inline-block w-full" to={"/"} replace>
            <img
              className=" h-full w-full object-contain "
              src={"/logo_general.png"}
              alt={"Logo"}
            />
          </Link>

          {/* {!isMenuOpen && (
            <Link className="w-full" to={"/"} replace>
              <div className="text-lg text-white font-bold leading-tight">
                {siteSetting?.title}
              </div>
            </Link>
          )} */}
        </div>
        <div
          className={
            "relative  overflow-auto scroll-smooth scrollbar scrollbar-1 h-[calc(100vh-4rem)] transition-all duration-100 " +
            (isMenuOpen ? "divide-y divide-slate-800 " : "")
          }
        >
          {Array.isArray(sideBar) &&
            adminSidebarMenu?.map((item, index) => (
              <SidebarMenuBlocks
                key={index}
                menuTitle={item.menuTitle}
                menuData={item.menuData}
                isMenuOpen={isMenuOpen}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default DefaultSidebar;
