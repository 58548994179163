import React from "react";
import Td from "./Td";
import { Draggable, Droppable } from "react-beautiful-dnd";

const Tbody = ({ tableData, isOrdering = false }) => {
  return (
    <Droppable droppableId="table-list" isDropDisabled={isOrdering}>
      {(provided) => (
        <tbody
          className="text-slate-700 text-sm font-light"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {tableData.map((item, i) => (
            <Draggable
              key={i}
              draggableId={`${item._id}${i}`}
              index={i}
              isDragDisabled={isOrdering}
            >
              {(provided) => (
                <tr
                  className="border-b border-slate-200"
                  key={item._id}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {item.rowData.map((itemData, index) => (
                    <Td
                      dropdownData={itemData?.actionData}
                      key={itemData._id}
                      type={itemData.type}
                      data={itemData.data}
                      image={itemData.image}
                      align={itemData.align}
                      statusLabel={itemData.statusLabel}
                      statusType={itemData.statusType}
                      onStatusChange={itemData.onStatusChange}
                      checkboxChecked={itemData.checked || false}
                      onInputChange={itemData.onChange}
                      isStatusChangeRequired={itemData.isStatusChangeRequired}
                      onClick={itemData.onClick}
                      onCheckboxChange={() =>
                        itemData.onCheckboxChange
                          ? itemData.onCheckboxChange(
                              itemData.itemDetails || {}
                            )
                          : () => {}
                      }
                      index={index}
                    />
                  ))}
                </tr>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
};

export default Tbody;
