import React, { useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useRef, useState } from "react";
import { bottom } from "@popperjs/core";

import Image from "../components/elements/Image";
import { Link, useNavigate } from "react-router-dom";

import Modal from "../common/Modal";
import { useDispatch, useSelector } from "react-redux";

const UserDropdown = ({ xPlacement, ...props }) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { user } = useSelector((state) => state.user);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
  const [isModalOpen, setIsModalOpen] = useState({ logOut: false });

  const dropdownData = [
    {
      _id: 1,
      label: "Profile",
      icon: "fa-regular fa-user",
      link: "/profile",
      standout: false,
    },
    {
      _id: 2,
      label: "Logout",
      icon: "fa-arrow-right-from-arc",
      standout: true,
      onClick: () => {
        setIsModalOpen((prev) => ({ ...prev, logOut: true }));
      },
    },
  ];

  const logOut = async () => {
    if (localStorage.getItem("auth_token")) {
      localStorage.removeItem("auth_token");
      window.dispatchEvent(new Event("auth_token"));
      return;
    }
  };
  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div
                className={`relative w-full h-10 flex items-center justify-center focus:ring-0 transition-all duration-200 ${
                  !user ? "animate-pulse bg-gray-300" : null
                }`}
              >
                <div className="w-8 h-8 min-w-[32px] overflow-hidden rounded-full border-2">
                  {/* <Image
                    src={
                      user?.image?.url
                        ? user?.image?.url
                        : "./images/noImage.jpg"
                    }
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                  /> */}
                  <Image
                    src={user?.image || "/images/avtar.svg"}
                    alt={"data"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                  />
                </div>
                <div className="text-sm font-semibold text-slate-500 mx-2 whitespace-nowrap">
                  {`${user?.firstName ? user?.firstName : ""} ${
                    user?.lastName ? user?.lastName : ""
                  }`}
                </div>
                <div className="text-xs text-slate-400">
                  <i
                    className={
                      "fa-light fa-fw " +
                      (open ? "fa-chevron-up" : "fa-chevron-down")
                    }
                  ></i>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-40 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0">
                <div className="py-1 max-h-80 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar">
                  {dropdownData.map((item) => {
                    if (item?.link) {
                      return (
                        <Menu.Item key={item._id}>
                          <Link
                            to={item.link}
                            className={
                              "flex items-center py-2 px-3 gap-3 text-slate-600 font-medium transition-all duration-200 " +
                              (item.standout
                                ? "text-rose-500 hover:bg-rose-100"
                                : "text-slate-500 hover:bg-slate-100")
                            }
                          >
                            <div className="w-5 h-5 flex items-center justify-center text-base">
                              <i
                                className={"fa-regular fa-fw " + item.icon}
                              ></i>
                            </div>
                            <div className="text-xs">{item.label}</div>
                          </Link>
                        </Menu.Item>
                      );
                    } else {
                      return (
                        <Menu.Item key={item._id}>
                          <div
                            className={
                              "flex items-center py-2 px-3 cursor-pointer gap-3 text-slate-600 font-medium transition-all duration-200 " +
                              (item.standout
                                ? "text-rose-500 hover:bg-rose-100"
                                : "text-slate-500 hover:bg-slate-100")
                            }
                            onClick={item.onClick}
                          >
                            <div className="w-5 h-5 flex items-center justify-center text-base">
                              <i
                                className={"fa-regular fa-fw " + item.icon}
                              ></i>
                            </div>
                            <div className="text-xs">{item.label}</div>
                          </div>
                        </Menu.Item>
                      );
                    }
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <Modal
        isOpen={isModalOpen.logOut}
        onCancel={() => setIsModalOpen((prev) => ({ ...prev, logOut: false }))}
        onSucess={logOut}
        sucessText="Logout"
        successClasses="!bg-red-500 "
      >
        <h2 className="text-lg font-semibold my-3">Logout Confirmation</h2>
        <p>Are you sure that you want to Logout?</p>
      </Modal>
    </>
  );
};

export default UserDropdown;
