import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DefaultSidebar from "../shared/DefaultSidebar";
import DefaultHeader from "../shared/DefaultHeader";
import { fetchData } from "../helpers/fetchData";
import { useDispatch, useSelector } from "react-redux";
import { setRoles } from "../store/slices/roleSlice";
import { toast } from "react-toastify";
import { setUser } from "../store/slices/userSlice";
import { AUTH_TOKEN, REFRESh_TOKEN } from "../utils/constant";
import Loader from "../common/Loder";

const DefaultLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  // const roles = useSelector((state) => state.roles);

  const getProfile = useCallback(async () => {
    try {
      let resp = await fetchData("get", "admin/details");
      if (resp?.data?.status === 200) {
        dispatch(setUser(resp?.data?.data));
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  return (
    <>
      <div className="relative flex w-full h-screen overflow-hidden">
        <div
          className={
            "fixed h-screen flex transition-all duration-100 z-10 " +
            (isMenuOpen ? "w-16" : "w-60")
          }
        >
          <DefaultSidebar isMenuOpen={isMenuOpen} />
        </div>
        <div
          className={
            "relative w-full flex flex-col transition-all duration-100 " +
            (isMenuOpen ? "pl-16" : "pl-60")
          }
        >
          <DefaultHeader
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
          <div className="relative w-full h-full max-h-[calc(100vh-4rem)] p-6 overflow-auto scroll-smooth scrollbar bg-slate-100">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
