import React from "react";
import Button from "../components/form/Button";
import NotificationDropdown from "./NotificationDropdown";
import UserDropdown from "./UserDropdown";

const DefaultHeader = ({ isMenuOpen, setIsMenuOpen }) => {
  const adminAccessToken = localStorage.getItem("admin_accessToken");
  const adminRefreshToken = localStorage.getItem("admin_refreahToken");
  const companyAccessToken = localStorage.getItem("company_accessToken");
  const companyRefreshToken = localStorage.getItem("company_refreahToken");
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header className="sticky top-0 h-16 min-h-[64px] z-50 w-full flex items-center px-6 white shadow gap-4">
        <div className="relative flex gap-2">
          <Button
            buttonType={"button"}
            buttonIcon={isMenuOpen ? "fa-solid fa-bars" : "fa-solid fa-xmark"}
            buttonIconPosition={"left"}
            buttonClasses={
              "px-0 w-10 !bg-slate-200 !text-slate-600 hover:!bg-slate-300 hover:!text-slate-900"
            }
            buttonFunction={menuToggle}
          />
        </div>
        <div className="relative ml-auto flex justify-end items-center gap-3">
          <div className="relative">
            <UserDropdown xPlacement={"right"} />
          </div>
        </div>
      </header>
    </>
  );
};

export default DefaultHeader;
